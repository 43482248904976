import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { SERVER_URL } from '../constants';
import Button from './Button';
import './RainbowBorder.css';

const Question = styled.p`
  width: 100%;
  font: "Avenir Next", Helvetica, Arial, sans-serif;
  font-size: 35px;
  font-weight: 600;
  overflow-wrap: break-word;
  margin-bottom: 8px;
`

const Stage = styled.div`
margin: 20%;
border-radius: 6px;
background-color: white;
text-align: center;
box-shadow: 0 4px 8px rgba(0,0,0,0.25);
padding: 6px;
`

const Content = styled.div`
width: 100%;
max-width: 640px;
margin: auto auto;
display: flex;
flex-direction: column;
align-items: center;
`

const Grower = styled.div`
width: 75%;
padding: 20px;
border-radius: 8px;
margin: 20px;
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
text-align: center;
background: white;
`

const FullScreenQuestion = styled.div`
  position: fixed; /* Position it relative to the viewport */
  top: 0; /* Align to the top of the viewport */
  left: 0; /* Align to the left of the viewport */
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background: rgba(255, 255, 255, 1); /* Slightly transparent white background */
  z-index: 1000; /* High z-index to ensure it's above other elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px; /* Padding to avoid content touching the edges */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow-y: auto; /* Enable scroll if content is too long */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Button text color, change as needed */
`;

const formatter = new Intl.NumberFormat(navigator.language || 'en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
})

interface Props {
    user: string;
    publicKey: PublicKey;
    auth: string;
    onSignMessage: MouseEventHandler;
}

function AnswerQuestions({ user, publicKey, auth, onSignMessage }: Props) {
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    useEffect(() => {
        if (auth) {
            fetch(`${SERVER_URL}/${user}/question`, {
                method: "GET",
                headers: {
                    'Authorization': auth
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    setQuestions(data);
                })
                .catch((error) => console.error('Error fetching questions:', error));
        }
    }, [auth]);


    return (
        <>
            {auth ? (
                questions.length ? (
                    <Content>
                        {questions.map((question, index) => (
                            <Grower
                                className={question.tip ? 'rainbow-border' : ''}
                                key={index}
                                onClick={() => setSelectedQuestion(question)}
                            >
                                <Question>{question.question}</Question>
                                <p>{question.tip ? formatter.format(question.tip / LAMPORTS_PER_SOL) : '0'} SOL</p>
                            </Grower>
                        ))}
                    </Content>
                ) : (
                    <Stage>
                        <h1>No messages yet</h1>
                    </Stage>
                )
            ) : (
                <Stage>
                    <Button style={{ marginBottom: '12px' }} onClick={onSignMessage}>Sign In</Button>
                    <h1>Click "Sign In" to sign a message that proves ownership of this inbox</h1>
                </Stage>
            )}

            {selectedQuestion && (
                <FullScreenQuestion>
                    <CloseButton onClick={() => setSelectedQuestion(null)}>X</CloseButton>
                    <Question>{selectedQuestion.question}</Question>
                    <p>{selectedQuestion.tip ? formatter.format(selectedQuestion.tip / LAMPORTS_PER_SOL) : '0'} SOL</p>
                </FullScreenQuestion>
            )}
        </>
    );
}

export default AnswerQuestions;
