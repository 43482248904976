import React from "react";
import styled from 'styled-components';

const Content = styled.div`
width: 100%;
max-width: 640px;
margin: 20% auto;
display: flex;
flex-direction: column;
align-items: center;
`

const Stage = styled.div`
width: 100%;
overflow-wrap: break-word;
border-radius: 6px;
background-color: white;
text-align: center;
box-shadow: 0 4px 8px rgba(0,0,0,0.25);
padding: 6px;
`

const ProfileLink = styled.h1`
margin-bottom: 10px;
`

export default function NoUserSelectedPage({ publicKey }) {
    return (
        <Content>
            <Stage>
                <h2 style={{ marginBottom: '10px' }}>Go to</h2>
                <ProfileLink>https://{window.location.hostname}/user/solanaAddress</ProfileLink>
                <h2 style={{ marginBottom: '10px' }}>to send that person a message.</h2>
                <h2 style={{ marginBottom: '10px' }}>You do not need to sign in to send a message.</h2>
                {publicKey ?
                    <h2>Check out <a href={`/user/${publicKey}`}>your inbox at your address</a></h2>
                    :
                    <></>
                }
            </Stage>
        </Content>
    )
}
