import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export const RED = '#EB3742';
export const YELLOW = '#FFDC62';
export const GREEN = '#21E56F';
export const BLUE = '#59cff7';
export const PURPLE = '#8A81F8';
export const WHITE = '#FFFFFF';
export const GRAY = '#777777';
export const REACT_GRAY = '#222222';
export const DARK_GRAY = '#333333';
export const LIGHT_GRAY = '#444444';
export const BLACK = '#000000';

export const AUTHORIZATION_KEY = "Authorization"
export const SERVER_URL = "https://ngl-server.themacrochip.workers.dev"
// export const SERVER_URL = "http://localhost:8787"
export const RPC_URL = 'https://mainnet.helius-rpc.com/?api-key=a9239f25-6769-40c1-820d-dfdeb5c9c5bb'; //TODO put this on the server or something to make it dynamically switchable
// export const RPC_URL = 'https://api.devnet.solana.com'
export const NETWORK = WalletAdapterNetwork.Mainnet
// export const NETWORK = WalletAdapterNetwork.Devnet;
