import { WalletAdapterProps } from '@solana/wallet-adapter-base';
import { Connection, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SERVER_URL } from '../constants';

const Content = styled.div`
width: 100%;
max-width: 640px;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
`

const Grower = styled.div`
width: 100%;
`

const Stage = styled.div`
margin-top: 20%;
margin-bottom: 30px;
border-radius: 6px;
background-color: white;
text-align: center;
box-shadow: 0 4px 8px rgba(0,0,0,0.25);
padding: 4em;
`

const TextualArea = styled.textarea`
width: 100%;
padding: 18px 24px;
height: 140px;
box-sizing: border-box;
font-size: 20px;
font-weight: 600;
border: none;
outline: none;
background-color: transparent;
margin-bottom: 10px;
background-color: rgba(255,255,255,0.4);
backdrop-filter: blur(24px);
-webkit-backdrop-filter: blur(6px);
border-radius: 0 0 24px 24px;
position: relative;
-webkit-appearance: none;
`

const Header = styled.h1`
overflow-wrap: anywhere;
padding: 16px 16px 12px;
font-size: 16px;
background-color: white;
border-radius: 32px 32px 0 0;
display: flex;
align-items: top;
justify-content: left;
margin-bottom: 0px;
`

const SubmitButton = styled.button`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
background-color: black;
border-radius: 100px;
border: none;
font-size: 18px;
font-weight: 700;
color: white;
text-decoration: none;
box-shadow: 0 4px 8px rgba(0,0,0,0.25);
height: 60px;
box-sizing: border-box;
cursor: pointer;
`
const TipInfo = styled.p`
margin: 3px;
`

const TipInput = styled.input`
border-radius: 8px;
margin-right: 5px;
`

export type SubmitQuestionProps = {
    sendTransaction: WalletAdapterProps['sendTransaction'];
    user: string;
    publicKey: PublicKey;
    auth: string;
    connection: Connection;
}


function SubmitQuestions({ user, publicKey, auth, sendTransaction, connection }: SubmitQuestionProps) {
    const [question, setQuestion] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [tipAmount, setTipAmount] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!question || !question.trim()) {
            setErrorText('Enter a message')
            return;
        }
        setErrorText('')
        try {
            let signature = '';
            let lamports = LAMPORTS_PER_SOL * tipAmount;
            if (tipAmount) {
                if (!publicKey) {
                    setErrorText('Connect wallet. Sending without a tip does not require a wallet.')
                    return;
                }
                //todo put up progress indicator
                const transaction = new Transaction()
                transaction.add(SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey(user),
                    lamports,
                }))
                //todo make non legacy transaction. maybe need to detect if wallet can do this
                const {
                    value: { blockhash, lastValidBlockHeight },
                } = await connection.getLatestBlockhashAndContext()
                signature = await sendTransaction(transaction, connection)
                await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature }) //TODO is this finalized or what
                //TODO be clear about if you tip and/or message was sent when either fails. they aren't atomic
            }
            await fetch(`${SERVER_URL}/${user}/question`, {
                method: "POST",
                body: JSON.stringify({ question, tipAmount: lamports, signature })
            })
            setSubmitted(true);
            setErrorText('')
        } catch (error) {
            console.log(error)
            setErrorText(error.toString())
        }
    };

    const handleNewQuestion = () => {
        setQuestion('');
        setSubmitted(false);
    };

    if (submitted) {
        return (
            <Content>
                <Stage>Question submitted!</Stage>
                <SubmitButton onClick={handleNewQuestion}>Send Another Question</SubmitButton>
            </Content>
        );
    }

    return (
        <Content>
            <Grower>
                <Header>Send a message to {user}</Header>
                <form onSubmit={handleSubmit}>
                    <TextualArea
                        value={question}
                        onChange={(e) => {
                            setErrorText('')
                            setQuestion(e.target.value);
                        }}
                        placeholder="Write your question here"
                    />
                    <label>Add a tip (optional):</label>
                    {/* todo clear out tip if wallet disconnect */}
                    <TipInfo>Tips highlight your message. Tips are sent even if your message is ignored. This may change in the future.</TipInfo>
                    <div style={{ marginBottom: '0.5em' }}>
                        <TipInput type="number" value={tipAmount} onChange={e => {
                            setErrorText('')
                            setTipAmount(Math.max(Number(e.target.value), 0));
                        }} />
                        <span>SOL</span>
                    </div>
                    <SubmitButton type="submit">Send{tipAmount ? ' Tip and' : ''} Message</SubmitButton>
                </form>
                {errorText ? <h2>Error: {errorText}</h2> : <></>}
            </Grower>
        </Content>
    );
}

export default SubmitQuestions;
