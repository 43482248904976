import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import AnswerQuestions from './AnswerQuestions';
import SubmitQuestions from './SubmitQuestions';

function ViewProfile({ publicKey, auth, sendTransaction, connection, onSignMessage }) {
    const { user } = useParams();
    if (publicKey?.toBase58() == user) {
        return (<AnswerQuestions user={user} publicKey={publicKey} auth={auth} onSignMessage={onSignMessage} />)
    } else {
        return (<SubmitQuestions user={user} publicKey={publicKey} auth={auth} sendTransaction={sendTransaction}
            connection={connection} />)
    }
}
export default ViewProfile
