import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useWallet, WalletProvider, ConnectionProvider, useConnection } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import type { Adapter } from '@solana/wallet-adapter-base';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import bs58 from 'bs58'

import { Sidebar, AutoConnectProvider } from './components';
import ViewProfile from './components/ViewProfile';
import NoUserSelectedPage from './components/NoUserSelectedPage';
import { AUTHORIZATION_KEY, NETWORK, RPC_URL } from './constants';

const StyledApp = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const StyledSection = styled.section`
  background: #EC1187 linear-gradient(to bottom right, #EC1187 0%, #FF8D10 100%);
  position: relative;
  flex: 2;
  padding: 20px;
  overflow: auto;
  font-family: monospace;
`;

const message = 'Signing this message proves that you control your wallet. This is not a transaction and does not use gas.';

export type ConnectedMethods =
  | {
    name: string;
    onClick: () => Promise<string>;
  }
  | {
    name: string;
    onClick: () => Promise<void>;
  };

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const StatefullApp = () => {
  const topLevelWallet = useWallet();
  const { wallet, publicKey, connect, disconnect, signMessage, sendTransaction } = topLevelWallet;
  const connection = useConnection();
  const [auth, setAuth] = useState('')
  const prevWalletConnected = usePrevious(topLevelWallet.connected);

  useEffect(() => {
    if (prevWalletConnected && !topLevelWallet.connected) {
      localStorage.removeItem(AUTHORIZATION_KEY)
      setAuth('')
    } else {
      setAuth(localStorage.getItem(AUTHORIZATION_KEY))
    }
  }, [publicKey, wallet, topLevelWallet, prevWalletConnected]);

  const handleSignMessage = useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      const encodedMessage = new TextEncoder().encode(message);
      const signature = await signMessage(encodedMessage);
      localStorage.setItem(AUTHORIZATION_KEY, bs58.encode(signature))
      setAuth(bs58.encode(signature))
      console.log('auth set')
    } catch (error) {
      console.log(error)
    }
  }, [publicKey, signMessage, wallet]);

  const handleConnect = useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      await connect();
    } catch (error) {
      console.log(error)
    }
  }, [connect, publicKey, wallet]);

  const handleDisconnect = useCallback(async () => {
    if (!publicKey || !wallet) return;

    try {
      await disconnect();
    } catch (error) {
      console.log(error)
    }
  }, [disconnect, publicKey, wallet]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Disconnect',
        onClick: handleDisconnect,
      }
    ]
  }, [
    handleSignMessage,
    handleDisconnect,
  ]);

  return (
    <Router>
      <StyledApp>
        <Sidebar publicKey={publicKey} connectedMethods={connectedMethods} connect={handleConnect} />
        <StyledSection>
          <Routes>
            <Route path="/user/:user" element={
              <ViewProfile
                publicKey={publicKey}
                sendTransaction={sendTransaction}
                auth={auth}
                connection={connection.connection}
                onSignMessage={handleSignMessage}
              />} />
            <Route path="*" element={<NoUserSelectedPage publicKey={publicKey?.toBase58()} />} />
          </Routes>
        </StyledSection>
      </StyledApp >
    </Router >
  );
};
// npx serve st router can handle it also look up if wranger dev and cloudflare prod can handle it
const App = () => {

  const autoConnect = async (adapter: Adapter) => {
    adapter.autoConnect().catch((e) => {
      return true;
    });
    return false;
  };

  return (
    <AutoConnectProvider>
      <ConnectionProvider endpoint={RPC_URL}>
        <WalletProvider wallets={[]} autoConnect={autoConnect}>
          <WalletModalProvider>
            <StatefullApp />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </AutoConnectProvider>
  );
};

export default App;
