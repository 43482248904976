import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

require('./index.css')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
